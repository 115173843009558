/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import React from "react"
import { SSRProvider } from "react-bootstrap"
import BeaverLogger from "./src/constants/new-error-logger"
import "./src/styles/style.scss"
export { wrapPageElement } from "./gatsby-shared"

let httpLink = new HttpLink({
  uri: process.env.GATSBY_GRAPHCMS_API,
  headers: {
    Authorization: `Bearer ${process.env.GATSBY_GRAPHCMS_TOKEN}`,
  },
  fetch,
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})

// Wraps root in a component
export const wrapRootElement = ({ element, props }) => {
  return (
    <ApolloProvider client={client} {...props}>
      <SSRProvider>{element}</SSRProvider>
    </ApolloProvider>
  )
}

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

const addScriptHead = src => {
  const s = document.createElement(`script`)
  s.type = `text/javascript`
  s.src = src
  s.async = true
  document.getElementsByTagName(`head`)[0].appendChild(s)
}

export const onClientEntry = () => {
  addScript(
    "https://cdn.jsdelivr.net/npm/beaver-logger@4.0.28/dist/beaver-logger.min.js"
  )
  BeaverLogger()

  var count = 0
  document.onmousemove = function() {
    if (count === 0) {
      // addScript("https://fast.wistia.com/embed/medias/wu46rpgpff.jsonp")
      addScript("https://fast.wistia.com/assets/external/E-v1.js")
      count = count + 1
    }
  }

  addScriptHead("//script.crazyegg.com/pages/scripts/0104/8748.js")
}
